$primaryColor: #0151a1;
$secondColor: #0094d1;


.btnPrimaryColor {
  background-color: $primaryColor;
  color: white;
}

.btnSecondColor {
  background-color: $secondColor;
  color: white;
}
