@import "../../style/variables";

.backgroundContainer {
  background-image: linear-gradient(to right, $primaryColor , $secondColor);
  height: 100vh;
  width: 100vw;
  align-items: center;
  display: flex;
  justify-content: center;
}


.containerForm {
  background-color: white;
  border: 3px solid $secondColor;
  height: calc(100vh/2);
  width: calc(100vw/3);
  border-radius: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btnLogin {
  background: linear-gradient(to right, $primaryColor , $secondColor);
  border: 0!important;
}
